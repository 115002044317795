import React from "react";

export default function Header() {
  return (
    <header>
      <div className='header-inner'>
        <div className='logo'>FAHAD AHMAD</div>
        {/* <nav> */}
          {/* <ul>
            <li>
              <a href='/'>Projects</a>
            </li>
            <li>
              <a href='/'>About</a>
            </li>
            <li>
              <a href='/'>Contact</a>
            </li>
          </ul> */}
        {/* </nav> */}
      </div>
    </header>
  );
}
